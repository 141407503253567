export default [
  {
    header: 'Colaborador',
    resource: 'chats',
    action: 'listar',
  },

  {
    title: 'Marcas',
    route: 'marcas',
    icon: 'ClipboardIcon',
    resource: 'marcas',
    action: 'menu',

  },
  {
    title: 'Produtos',
    route: 'produtos',
    icon: 'CoffeeIcon',
    resource: 'produtos',
    action: 'menu',

  },
  {
    title: 'Chat',
    route: 'chat',
    icon: 'MessageSquareIcon',
    resource: 'chats',
    action: 'listar',
  },
]
