export default [
  {
    header: 'Acesso SAM',
  },

  {
    title: 'Permissões',
    route: 'permissões',
    icon: 'UserPlusIcon',
    resource: 'permissões',
    action: 'menu',
  },
 
]
