<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
       © {{ new Date().getFullYear() }}
  
      <span class="d-none d-sm-inline-block">- Todos os direitos reservados</span>
    </span>

    <span class="float-md-right d-none d-md-block">
      <a v-if="$can('abrir', 'tickets')" class="text-primary" href="https://pensousistemas.atlassian.net/servicedesk/customer/portal/1"
         target="_blank"
      > <feather-icon
          icon="InfoIcon" size="14"
      /></a>
      Pensou Sistemas

    </span>
  </p>
</template>

<script>
import { BButton, BLink } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BLink,
  },
}
</script>
